
//navbar CSS


.controlBar{
    background: #FFFFFF;
    padding: 0.75rem 2rem;
    .controlBarContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .controlBarBackButton{
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.34285715222358704px;
            text-align: left;
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            border: none;
            img{
                margin-right: 5px;
                width: 15px;
            }
        }:hover{
            cursor: pointer;
        }
        .controlBarForm{

            label {
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
    
            }
            input{
                left: 940.800048828125px;
                border-radius: 3px;
                padding: 5px;
                margin: 12px;
                background: #D9DEE9;
                outline: none;
                border: none;
            }
            :focus{
               outline: none;  
            } 
            .controlBarInputDate{
                height: 25px;
                width: 100px;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: left;
                                
            }
            .controlBarInputId{
                width: 230px;
                height: 25px;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-align: left;                    
                
            }
            .controlBarSubmitButton{
                width: 150px;
                height: 35px;
                border-radius: 70px;
                border: none;
                color: #FFFFFF;
                background: #157AFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                padding: 10px;
            }
        }
    }     
}


//Content CSS

.mainContainer{
    // overflow-y: scroll;
    height: 100%;
}

.Main{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #E5E5E5;
    height: 100%;

    .TableContainer{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 5px;
        height: 80%;
        max-width: 70%;
        min-width: 70%;

        .TableTitleContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
            gap: 15em;
        }

        .TableTitle{
            display: flex;
            padding: 10px 90px;
            background: #E5E5E5;
            .h2{
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.34285715222358704px;
                text-align: center;        
            }
        }

    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;
        width: 65%;
    
        img{
            width: 200px;
            height: 200px;
        }
    }

    .TableUrl{
        min-width: 80%;
        max-width: 90%;
        height: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;    
        border-left:1px  solid #CACCCB;
        border-right:1px  solid #CACCCB;
        border-bottom:1px  solid #CACCCB;
        table{
                border-collapse: collapse;
                thead{
                    background-color: #010101;
                    color: #FFFFFF;
                    height: 75px;
                    :first-child{
                        border-radius:10px 0 0 10px;
                        }
                        :last-child{
                            border-radius:0 10px  10px 0;
                        }
                    tr{
                        .Webapp{
                            text-align: center;
                        }
                        th{
                            min-width: 110px;
                            padding:  10px;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.34285715222358704px;
                            text-align: center;      
                        }
                    }
                }
                        
                tbody{
                    height: 100%;
                    tr{
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 1.5px;
                        &:nth-child(even){
                            background-color: #FFFFFF;
                        }
                        td{
                            padding: 7px;
                            font-family: Montserrat;
                            font-size: 11px;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 1.5px;
                            text-align: center;
                        }
                        .Trow{
                            padding: 10px 10px;
                            font-family: Montserrat;
                            font-size: 10px;
                            font-weight: 700;
                            line-height: 16px;
                            letter-spacing: 1.5px;
                            text-align: center;
                            color:#157AFF;  
                            cursor: pointer;

                            &:hover{
                                text-decoration: underline;
                            }

                            }
                            .Action{
                                width: 200px;
                                display: flex;
                                justify-content:space-between;
                                align-items: center;
                                padding: 16px;
                                img{
                                    padding: 0 10px;
                                }
                            }
                    }
                }
                    
            }
            
        }
        // ::-webkit-scrollbar {
        //     display: none;
        //   }
    .ScrollImageContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        flex-direction: column;
        display: none;

        img{
            width: 50px;
            animation: 2s linear 0s leftRight infinite;
            animation-direction: alternate;
            margin-bottom: 10px;
        }

        p{
            font-family: Montserrat;
            font-size: 11px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 1.5px;
            text-align: center;
            animation: 2s linear 0s leftRight infinite;
            animation-direction: alternate;
        }

        @keyframes leftRight {
            0%   {transform: translateX(-55px);}
            50%  {transform: translateX(-45px)}
            100% {transform: translateX(-40px)}
        }
    }
    .GenerateUrlBoxContainer{
        max-width: 30%;
        min-width: 30%;
        .GenerateUrlBox{
            position: relative;
            margin-top: 40px;
            height: 450px;
            width:350px;
            background-color: #000000;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 30px;
            border-radius: 10px;
            p{
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.34285715222358704px;
                text-align: center;
                color: #FFFFFF;
                margin: 10px;
            }
            button{
                margin: 10px;
                background: #157AFF;
                color: #FFFFFF;
                width: 160px;
                height: 40px;
                border-radius:60px;
                border: none;
                cursor: pointer;
            }
            .Logo{
                margin: 10px;
                height: 70px;
                width: 70px;
            }
        }

    }

}

  
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: #c2c2c2b3;

    i{
        background-color: #157AFF;
        color: white;
        font-size: 25px;
        padding: 3px;

        &:hover{
            background-color: #005baa;
            cursor: pointer;
        }
    }

    h4{
        padding: 5px;

        &:hover {
            background-color: #157AFF;
            color: white;
            cursor: pointer;
        }
    }
}

.remarkModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #cacaca41;

    .remarkModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 600px;
        height: 300px;
        border-radius: 50px;
        padding: 30px;
        gap: 2em;

        h4{
            font-family: Montserrat;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
        }

        textarea{
            width: 400px;
            height: 150px;
            font-size: 20px;
            padding: 3px;
        }

        .remarkModalButtonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6em;

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                background-color: #157AFF;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (width: 768px){
   .Main{
    .ScrollImageContainer{
            display: block;
    }
   } 
}