

.mainContainer{
    overflow-y: scroll;
    height: 100%;
    background: #e5e5e5;
}

.mainSubContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #e5e5e5;
}

.InspectionHeader{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #e5e5e5;
    width: 100%;

    .InspectionTitle{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 700;
        line-height: 17px;
        padding: 20px;
        letter-spacing: 0.34285715222358704px;
        color: #000000;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: #c2c2c2b3;

        i{
            background-color: #157AFF;
            color: white;
            font-size: 25px;
            padding: 3px;

            &:hover{
                background-color: #005baa;
                cursor: pointer;
            }
        }

        h4{
            padding: 5px;

            &:hover {
                background-color: #157AFF;
                color: white;
                cursor: pointer;
            }
        }
    }
    .reportDownload{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8em;

        .monthList{
            width: 150px;
            border-radius: 3px;
            padding: 5px;
            background: #D9DEE9;
            color: #273240;
            outline: none;
            border: none;
        }

        .ReportDownloadBtn{
            width: auto;
            height: 30px;
            border-radius: 70px;
            border: none;
            color: #FFFFFF;
            background: #157AFF;
            font-family: Montserrat;
            font-size: 11px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 1.5px;
            padding: 7px;

            &:hover{
                cursor: pointer;
            }

            .reportDownloadLoading{
                width: 10px;
                height: 10px;
            }
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;

    img{
        width: 100px;
        height: 100px;
    }
}

.InspectionTable{
        background: #E5E5E5;
        // overflow: scroll;
        width: 95%;
        border-left: 2px solid #CACCCB;
        border-right: 2px solid #CACCCB;
        border-bottom: 2px solid #CACCCB;
        overflow: auto;
        height: 100%;
        }
        .Table{
            border-collapse: collapse;
            max-width: 100%;
            min-width: 100%;
            thead{
                position: sticky;
                top: 0;
                background-color:#000000;
                color: white;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.34285715222358704px;
                tr{
                    .InspectionTableHead{
                        text-align: left;
                        padding: 18px;
                        min-width: 100px
                    }&  :first-child{
                        border-radius:15px 0 0 15px;
                    }& :last-child{
                        border-radius:0 15px  15px 0;
                    }
                }
            }
            tbody{
                text-align: center;
                color: white;
                tr{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: left;
                    &:nth-child(even){
                        background-color: #FFFFFF;
                    }
                    td{
                        color: #273240;
                        padding:12px 10px;      
                        width: 220px;
                        height: 30px;
                        overflow: hidden;
                        white-space: nowrap; 
                        text-overflow: ellipsis;
                        
                        .Remark{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 2em;
                            img{
                                cursor: pointer;
                            }
                            p{
                                width: 100px; 
                               overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .icon{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 1em;

                            .infoContainer{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                i{
                                    color: #157AFF;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .Footer{
            height: 100px;
            width: 100%;
            background-color: #E5E5E5;
            display: flex;
            align-items: center;
            padding-left: 50px;
            p{
                padding-left: 10px;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.34285715222358704px;
                text-align: left;
            }
        }

.remarkModalContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #cacaca41;

    .remarkModal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #FFFFFF;
        width: 600px;
        height: 300px;
        border-radius: 50px;
        padding: 30px;
        gap: 1em;

        h4{
            font-family: Montserrat;
            line-height: 17px;
            letter-spacing: 0.34285715222358704px;
        }

        textarea{
            width: 400px;
            height: 150px;
            font-size: 20px;
            padding: 3px;
        }

        .remarkModalButtonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6em;

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                background-color: #157AFF;
                color: #FFFFFF;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                border-radius: 20px;
                cursor: pointer;
            }
        }
    }
}

.ScrollImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;

    img{
        width: 50px;
        animation: 2s linear 0s leftRight infinite;
        animation-direction: alternate;
    }

    p{
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-align: center;
        animation: 2s linear 0s leftRight infinite;
        animation-direction: alternate;
    }

    @keyframes leftRight {
        0%   {transform: translateX(-35px);}
        50%  {transform: translateX(-25px)}
        100% {transform: translateX(-20px)}
    }
}




.vin{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

    img{
        width: 45px;
        object-fit: contain;
    }

    span{
        font-weight: 600;
        cursor: pointer;
    }
}